import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let firebaseConfig = {
  apiKey: "AIzaSyB1Mz_HycMO9F2LEq5cyC5sUO3kBAbIDso",
  authDomain: "weshare4kids.firebaseapp.com",
  projectId: "weshare4kids",
  storageBucket: "weshare4kids.appspot.com",
  messagingSenderId: "1010729565277",
  appId: "1:1010729565277:web:e55dbea12dcb84ea3561b4",
};

console.log("Starting Firebase");
let app = initializeApp(firebaseConfig);

// utils
const db = getFirestore(app);
const auth = getAuth(app);

// export utils/refs
export { db, auth };
