import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "@/firebase";
import { auth } from "./firebase";

loadFonts();
document.title = "Stichting WeShare4Kids";

let app;
auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit("setUser", user);
  }
  if (!app) {
    app = createApp(App).use(router).use(vuetify).use(store).mount("#app");
  }
});
