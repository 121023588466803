<template>
  <v-app>
    <HeaderNav v-if="!$route.path.includes('/admin')" />
    <v-navigation-drawer
      v-model="$store.state.drawer"
      v-if="this.$vuetify.display.smAndDown"
      app
      class="bg-background"
    >
      <v-list nav dense v-model="tab" active-class="primary--text">
        <v-list-item @click="$router.push('/')">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push('/overons')">
          <v-list-item-title>Over ons</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push('/organisatie')">
          <v-list-item-title>Organisatie</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push('/nieuws')">
          <v-list-item-title>Nieuws</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push('/contact')">
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/steunons')">
          <v-list-item-title
            ><v-icon size="x-small" class="mr-1">mdi-arrow-right</v-icon>Steun
            ons
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view v-slot="{ Component, route }">
        <transition name="slide-fade" mode="out-in">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </v-main>
    <FooterMenu v-if="!$route.path.includes('/admin')" />
  </v-app>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "App",
  components: {
    HeaderNav,
    FooterMenu,
  },
  mounted() {
    this.$store.dispatch("getNieuws");
  },

  watch: {
    $route: {
      handler: function (val) {
        if (val.path.includes("/organisatie")) {
          this.tab = 2;
        } else if (val.path.includes("/overons")) {
          this.tab = 1;
        } else if (val.path.includes("/nieuws")) {
          this.tab = 3;
        } else if (val.path.includes("/contact")) {
          this.tab = 4;
        } else {
          this.tab = 0;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data: () => ({
    tab: 0,
    drawer: true,
  }),
};
</script>

<style lang="scss">
h1 {
  font-weight: 700;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(5px);
  opacity: 0;
}

.section {
  max-width: 1200px;
  width: 90vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  margin: auto;
}
</style>
