<template>
  <div v-if="article" style="margin-bottom: 150px">
    <div class="section pt-8 d-flex flex-column align-center pb-8">
      <v-img
        height="30vh"
        width="100%"
        cover
        rounded=""
        class="mb-10 elevation-4 image"
        src="https://images.pexels.com/photos/220455/pexels-photo-220455.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      ></v-img>
      <div style="width: 100%; max-width: 700px">
        <b
          ><p>{{ article.location + " " + getDate(article.timestamp) }}</p></b
        >
      </div>
      <div style="width: 100%; max-width: 700px">
        <h1 class="my-6" style="font-size: 30pt">
          {{ article.title }}
        </h1>
      </div>
      <p style="font-size: 14pt; max-width: 700px; margin-bottom: 10px">
        {{ article.subtitle }}
      </p>
      <div
        style="width: 100%; max-width: 700px; font-size: 14pt"
        v-html="article.content"
      ></div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      nieuws: (state) => state.nieuws,
    }),
    article() {
      return this.nieuws.find((a) => a.uid === this.$route.params.id);
    },
  },
  methods: {
    getDate(d) {
      let i = DateTime.fromSeconds(d);
      return i.toLocaleString(DateTime.DATETIME_FULL);
    },
  },
};
</script>
