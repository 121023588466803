<template>
  <div style="padding: 5%; max-width: 1000px">
    <div class="d-flex justify-space-between align-center">
      <div>
        <h1 style="font-size: 30pt; font-weight: 600">Nieuws</h1>
        <p class="mb-10">Beheer alle nieuws artikelen</p>
      </div>
      <v-btn color="primary" @click="edit({ uid: 'new' })">
        Nieuw artikel</v-btn
      >
    </div>

    <v-data-table-virtual :headers="headers" :items="nieuws" item-value="name">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          variant="text"
          size="small"
          @click="$store.dispatch('deleteArticle', item)"
          icon="mdi-delete"
          class="mr-2"
        ></v-btn>
        <v-btn
          variant="text"
          size="small"
          @click="edit(item)"
          icon="mdi-pencil"
        ></v-btn>
      </template>
    </v-data-table-virtual>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      nieuws: (state) => state.admin_nieuws,
    }),
  },
  data() {
    return {
      headers: [
        { title: "Titel", align: "start", key: "title" },
        {
          titel: "Acties",
          align: "end",
          key: "actions",
        },
      ],
    };
  },
  methods: {
    edit(i) {
      console.log(i);
      this.$router.push(`/admin/edit/${i.uid}`);
    },
  },
};
</script>
