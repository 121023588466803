<template>
  <div>
    <div class="section">
      <h1>Steun ons</h1>
      <p class="subtitl">
        Er zijn meerdere mogelijkheden waarop u ons kunt steunen. Neem
        vrijblijvend contact met ons op wanneer u vragen heeft of wilt steunen.
      </p>

      <div class="mogelijkheden">
        <v-card class="pa-8">
          <v-img
            class="mb-4"
            max-width="50"
            :src="require('@/assets/sun.svg')"
          ></v-img>
          <h2 class="mb-2">Uw huis ter beschikking stellen</h2>
          <p>
            Door uw (vakantie)huis ter beschikking te stellen, kunnen gezinnen
            met zieke kinderen hier gebruik van maken als plek waar ze een
            nieuwe start kunnen beleven.
          </p>
          <v-btn
            class="mt-4"
            variant="outlined"
            @click="$router.push('/contact')"
            >Neem contact op</v-btn
          >
        </v-card>

        <v-card class="pa-8">
          <v-img
            class="mb-4"
            max-width="50"
            :src="require('@/assets/sun.svg')"
          ></v-img>
          <h2 class="mb-2">Sponsor worden</h2>
          <p>
            U kunt sponsoren op elke manier, door bijv. bij te dragen aan zaken
            die elke stichting nodig heeft. Denk aan marketing, administratief,
            juridisch, IT en meer.
          </p>
          <v-btn
            class="mt-4"
            variant="outlined"
            @click="$router.push('/contact')"
            >Neem contact op</v-btn
          >
        </v-card>

        <v-card class="pa-8">
          <v-img
            class="mb-4"
            max-width="50"
            :src="require('@/assets/sun.svg')"
          ></v-img>
          <h2 class="mb-2">Vrijwilliger worden</h2>
          <p>
            Wilt u persoonlijk meehelpen met de stichting? Neem dan contact met
            ons op om in gesprek te gaan over de mogelijkheden.
          </p>
          <v-btn
            class="mt-4"
            variant="outlined"
            @click="$router.push('/contact')"
            >Neem contact op</v-btn
          >
        </v-card>

        <v-card class="pa-8">
          <v-img
            class="mb-4"
            max-width="50"
            :src="require('@/assets/sun.svg')"
          ></v-img>
          <h2 class="mb-2">Donateur worden</h2>
          <p>
            Met donateurschap steunt u de stichting met een financiele bijdrage.
            Hiermee kunnen we de vakanties voor de gezinnen zo kosteloos als
            mogelijk maken.
          </p>
          <v-btn
            class="mt-4"
            variant="outlined"
            @click="$router.push('/contact')"
            >Neem contact op</v-btn
          >
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mogelijkheden {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.subtitl {
  font-size: 14pt;
  margin-bottom: 5vh;
  max-width: 50%;
}

@media screen and (max-width: 700px) {
  .mogelijkheden {
    grid-template-columns: 1fr;
  }
  .subtitl {
    max-width: 100%;
  }
}
</style>
