import { createStore } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/firebase";

export default createStore({
  state: {
    drawer: false,
    nieuws: [],
    nieuwsbrieven: [],
    admin_nieuws: [],
    user: null,
    saving: false,
  },
  getters: {},
  mutations: {
    setDrawer(state, val) {
      state.drawer = val;
    },
    setNieuws(state, val) {
      state.nieuws = val;
    },
    setUser(state, val) {
      state.user = val;
    },
    setSaving(state, val) {
      state.saving = val;
    },
    setAdminNieuws(state, val) {
      state.admin_nieuws = val;
    },
    setNieuwsbrieven(state, val) {
      state.nieuwsbrieven = val;
    },
  },
  actions: {
    async getNieuws({ commit, state }) {
      let articles = [];
      let admin = [];
      const q = query(collection(db, "nieuws"), orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().public) {
          articles.push(doc.data());
        }
        if (state.user) {
          admin.push(doc.data());
        }
      });

      commit("setNieuws", articles);
      commit("setAdminNieuws", admin);
    },
    async getNieuwsbrieven({ commit, state }) {
      if (state.user) {
        let signups = [];

        const q = query(collection(db, "nieuwsbrief"), orderBy("date", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          signups.push(doc.data());
        });
        console.log(signups);

        commit("setNieuwsbrieven", signups);
      }
    },
    async updateArticle({ commit, dispatch }, val) {
      commit("setSaving", true);
      const functions = getFunctions();
      const updateArticle = httpsCallable(functions, "updateArticle");
      const newArticle = httpsCallable(functions, "newArticle");

      if (val.uid) {
        await updateArticle(val);
        dispatch("getNieuws");
      } else {
        await newArticle(val);
        dispatch("getNieuws");
      }

      commit("setSaving", false);
      alert("Artikel opgeslagen!");
    },
    async deleteArticle({ dispatch }, i) {
      let r = confirm("Weet je het zeker dat je dit artikel wilt verwijderen?");
      if (r) {
        await deleteDoc(doc(db, "nieuws", i.uid));
        alert("Artikel verwijderd!");
        dispatch("getNieuws");
      }
    },
  },
  modules: {},
});
