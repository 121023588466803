<template>
  <div v-if="article" style="padding: 5%; max-width: 1000px">
    <div class="mb-4 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-btn
          variant="tonal"
          size="small"
          @click="$router.push('/admin/nieuws')"
          icon="mdi-arrow-left"
          class="mr-2"
        ></v-btn>
        <h1>Artikel aanpassen</h1>
      </div>
      <div class="d-flex align-center" style="gap: 25px">
        <v-switch
          color="success"
          hide-details=""
          label="Gepubliceerd"
          v-model="article.public"
        ></v-switch>
        <v-btn
          color="success"
          @click="$store.dispatch('updateArticle', article)"
          :loading="$store.state.saving"
          >Opslaan</v-btn
        >
      </div>
    </div>
    <div class="d-flex" style="gap: 10px">
      <v-text-field
        label="Titel"
        variant="solo"
        v-model="article.title"
      ></v-text-field>
      <v-text-field
        label="Locatie"
        variant="solo"
        v-model="article.location"
      ></v-text-field>
    </div>
    <div style="display: grid; grid-template-columns: 3fr 1fr; gap: 20px">
      <v-text-field
        v-model="article.header_image"
        label="Afbeelding url"
        variant="solo"
        height="200"
      ></v-text-field>
      <v-card class="pa-2 mb-4">
        <v-img v-if="article.header_image" :src="article.header_image"></v-img>
      </v-card>
    </div>
    <v-textarea
      label="Subtitel"
      variant="solo"
      v-model="article.subtitle"
    ></v-textarea>
    <EditorComp v-model="article.content" />
    <!-- <div>
      <h4 class="mt-10">Preview:</h4>
      <h1 class="mb-2">{{ article.title }}</h1>
      <p>{{ article.subtitle }}</p>
      <div v-html="article.content"></div>
    </div> -->
  </div>
</template>

<script>
import EditorComp from "@/components/Editor/EditorComp.vue";
import { mapState } from "vuex";

export default {
  components: {
    EditorComp,
  },
  computed: {
    ...mapState({
      nieuws: (state) => state.admin_nieuws,
    }),
    article() {
      if (this.$route.params.id === "new") {
        return this.newArticle;
      } else {
        return this.nieuws.find((a) => a.uid === this.$route.params.id);
      }
    },
  },
  data() {
    return {
      newArticle: {
        content: "",
        title: "",
        subtitle: "",
        header_image: "",
        location: "",
        public: false,
        timestamp: null,
        uid: null,
      },
    };
  },
};
</script>
