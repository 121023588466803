import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactView from "../views/ContactView.vue";
import NieuwsView from "../views/NieuwsView.vue";
import OrganisatieView from "@/views/OrganisatieView.vue";
import OverOns from "@/views/OverOns.vue";
import AdminView from "@/views/Admin/AdminView.vue";
import AdminNieuws from "@/views/Admin/AdminNieuws.vue";
import EditNieuws from "@/views/Admin/EditNieuws.vue";
import AdminNieuwsBrief from "@/views/Admin/AdminNieuwsBrief.vue";
import NieuwsArtikel from "@/views/NieuwsArtikel.vue";
import SteunOns from "@/views/SteunOns.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/nieuws",
    name: "Nieuws",
    component: NieuwsView,
  },
  {
    path: "/nieuws/:id",
    name: "Nieuws - Artikel",
    component: NieuwsArtikel,
  },
  {
    path: "/organisatie",
    name: "Organisatie",
    component: OrganisatieView,
  },
  {
    path: "/overons",
    name: "Over ons",
    component: OverOns,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/steunons",
    name: "Steun ons",
    component: SteunOns,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    redirect: "/admin/nieuws",
    children: [
      {
        path: "/admin/nieuws",
        name: "Admin Nieuws",
        component: AdminNieuws,
      },
      {
        path: "/admin/edit/:id",
        name: "Admin Nieuws Aanpassen",
        component: EditNieuws,
      },
      {
        path: "/admin/nieuwsbrief",
        name: "Admin Nieuwsbrief",
        component: AdminNieuwsBrief,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    return new Promise((resolve) => {
      if (to.hash) {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
          });
        }, 150);
      } else {
        resolve({ top: 0 });
      }
    });
  },
});

export default router;
